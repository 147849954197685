/*-----------------------------------------------
 共通JS
-----------------------------------------------*/

$(function(){

// ページトップへ戻るのスクロール
  var $pagetop = $('#pageTop');

  if ($(window).width() > 768) $pagetop.hide();
  $(window).scroll(function(){
    if($(window).scrollTop() > 200){
      $pagetop.fadeIn();
    } else {
      $pagetop.fadeOut();
    }
  });


  $pagetop.click(function(){
    $('html,body').animate({scrollTop:0},1000);
    return false;
  });

// アンカーリンクを押すと、スクロール
  $('a[href^=#]:not([class*="modal"])').click(function() {
      var speed = 400;
      var href= $(this).attr("href");
      var target = $(href == "#" || href == "" ? 'html' : href);
      var position = target.offset().top;
      $('body,html').animate({scrollTop:position}, speed, 'swing');
      return false;
  });

  //アコーディオン
  $('.accordion').each(function () {
    if ($(this).hasClass('active')) {
        var panel = $(this).next('.panel');
        $(this).next('.panel').css('max-height', panel[0].scrollHeight + "px");
    }
    $('.accordion').on('click', function () {
        $('.accordion').next('.panel').css('max-height', 0);
        $('.accordion').removeClass('active');
        panel = $(this).next('.panel');
        $(this).addClass('active');
        if (parseFloat($(this).next('.panel').css('max-height'))) {
            $(this).next('.panel').css('max-height', 0);
            $(this).removeClass('active');
        } else {
            $(this).next('.panel').css('max-height', panel[0].scrollHeight + "px");
        }
    });
  });

  //ハンバーガーメニュー
  function checkWidth() {
    winW = $(window).width();
    return winW;
  }

  function initMenu(){
    var winW = checkWidth();
    if (winW >= devW) {
      //PC
      $('.hideNavi1').hide();
    } else {
      //SP
      $('#gnavi>ul').hide();
    }
  }

  var devW = 800;　//breakpoint
  initMenu();//読み込み時の動作

  $(window).on('load resize', function() {
    var winW = checkWidth();

    if (winW >= devW) {
      //PC
      $('#gnavi>ul').show();
      $('.hideNavi1').hide();
      //hover
      $('#gnavi>ul>li.dropdown').hover(
        function() {
          //マウスカーソルが重なった時の処理
          $('.hideNavi1').fadeIn(200).css('display','flex');
        },
        function() {
          //マウスカーソルが離れた時の処理
          $('.hideNavi1').fadeOut(200);
        }
      );
    } else {
      //SP
      // $('#gnavi>ul').hide();
      // $('.toggleBtn').removeClass('active');

      $('.toggleBtn').off('click');//複数回処理を解除
      $('.toggleBtn').on('click', function() {
        $(this).toggleClass('active');
        $('#gnavi>ul').slideToggle();
        $('html').toggleClass('opened');
      });

      $('.dropdown.service>a').off('click');//複数回処理を解除
       $('.dropdown.service>a').on('click', function() {
         $('.hideNavi1').slideToggle();
         $(this).toggleClass('active');
       });
    }
  });


});
